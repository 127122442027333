<script setup lang="ts">
import { computed, Ref, ref } from 'vue'
import { Event } from '@/interfaces/Event'
import { Category } from '@/interfaces/Category'
import { FilterParameter } from '@/interfaces/FilterParameter'
import { useFilters } from '@/composables/useFilters'
import { CalendarEvent } from '@/interfaces/CalendarEvent'
import FiltersContainer from '@/components/Filters/FiltersContainer.vue'
import EventInformations from '@/components/Event/EventInformations.vue'
import EventCalendar from '@/components/Event/EventCalendar.vue'
import { Domain } from '@/interfaces/Domain'
import NoResultAnimation from '@/components/Lottie/NoResultAnimation.vue'

const props = defineProps<{
    events: Array<Event>,
    domains: Array<Domain>,
    categories: Array<Category>,
    getParameters: FilterParameter,
}>();

const handleSubmit = (data: FilterParameter) => {
    useFilters().handleSubmit(data);
}

const categoriesOptions = computed(() => props.categories.map(
    category => ({ value: category.slug, label: category.title })
));

const domainsOptions = computed(() => props.domains.map(
    domain => ({ value: domain.slug, label: domain.title })
));

const dateSelected: Ref<string> = ref('');

const selectedEventIds: Ref<string[]|undefined> = ref(undefined);

const eventClicked = (clickedEvent: CalendarEvent) => {
    selectedEventIds.value = [clickedEvent.id];
}

const eventClosed = () => {
    dateClicked(dateSelected.value);
}

const dateClicked = (date: string) => {
    dateSelected.value = date;
    selectedEventIds.value = undefined;
    selectedEventIds.value = props.events
        .filter(event => {
            const startDate = new Date(event.start_date);
            const endDate = new Date(event.end_date);
            const selectedDate = new Date(date);
            return selectedDate >= startDate && selectedDate <= endDate;
        })
        .map(event => event.id);
}

const initDateSelected = () => {
    if (props.getParameters.start_date && !dateSelected.value) {
        dateSelected.value = props.getParameters.start_date;
    } else if (!dateSelected.value) {
        const today = new Date();
        dateSelected.value = today.toISOString().split('T')[0];
    }
    dateClicked(dateSelected.value);
}

initDateSelected();
</script>

<template>
    <div class="container flex flex-col gap-8 mb-20">
        <h1>
            Les événements à venir
        </h1>

        <FiltersContainer
            :domains-options="domainsOptions"
            :categories-options="categoriesOptions"
            :get-parameters="props.getParameters"
            placeholder-search="Rechercher un événement"
            class="mb-12"
            @submit="handleSubmit"
        />

        <div class="flex flex-col-reverse md:flex-row gap-8 md:gap-16">
            <div class="w-full md:w-1/2">
                <div v-if="events.length > 0" class="flex flex-col space-y-5">
                    <EventInformations
                        v-for="(e) in events"
                        :key="e.id"
                        :event="e"
                        :selected-event-ids="selectedEventIds"
                    />
                </div>
                <p v-else>
                    <NoResultAnimation />
                </p>
            </div>
            <div class="w-full md:w-1/2 mb-4 md:mb-0">
                <EventCalendar
                    :events="props.events"
                    :date-start="props.getParameters.start_date"
                    @event-was-clicked="eventClicked"
                    @event-was-closed="eventClosed"
                    @date-was-clicked="dateClicked"
                />
            </div>
        </div>
    </div>
</template>
