<script setup lang="ts">
import { ref } from "vue";
import { Question } from "@/interfaces/Question";
import Slider from "@vueform/slider";

const props = defineProps<{
    questions: Array<Question>,
}>();

const CARD_IN_VIEWS = 3;
const CONTAINER_HEIGHT = 90;

const currentIndex = ref(0);
const sliderValues = ref<any>({});

function calculateTop(index: number) {
    const baseStep = 100 / (CARD_IN_VIEWS);

    const currentCenter = currentIndex.value - 1;

    if (index < currentIndex.value) {
        return `${-baseStep * (currentCenter - index)}%`;
    } else if (index > currentIndex.value) {
        return `${baseStep * (index - currentCenter)}%`;
    }
    return `${baseStep}%`;
}

function calculateOpacity(index: number) {
    if (index === currentIndex.value) {
        return 1;
    } else if (index === currentIndex.value - 1 || index === currentIndex.value + 1) {
        return 0.3;
    }
    return 0;
}

function goToPrevious() {
    if (currentIndex.value > 0) {
        currentIndex.value--;
    }
}

function goToNext() {
    if (currentIndex.value < props.questions.length - 1) {
        currentIndex.value++;
    }
}

function changeCurrent(index: number){
    if (index != currentIndex.value) {
        (index > currentIndex.value) ? goToNext() : goToPrevious();
    }
}
</script>

<template>
    <div class="flex relative max-w-3xl w-full px-4" :style="`height: ${CONTAINER_HEIGHT}svh`">
        <div class="w-full relative">
            <div
                v-for="(question, index) in questions" :key="index"
                :style="{
                    top: calculateTop(index),
                    opacity: calculateOpacity(index),
                    height: CONTAINER_HEIGHT / CARD_IN_VIEWS + 'svh'
                }"
                class="onboarding-card-container"
                :class="`h-[${CONTAINER_HEIGHT / CARD_IN_VIEWS}svh]`"
            >
                <div class="onboarding-card" @click="changeCurrent(index)">
                    <span>Question {{ index+1 }} sur {{ questions.length }}</span>
                    <p class="font-medium text-3xl mt-3">
                        {{ question.question_title }}
                    </p>
                    <div class="mt-6">
                        <div v-if="question.answer_type.value === 'select'">
                            <select
                                id="select" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
                            focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            >
                                <option v-for="(item, secondIndex) in question.answer_available" :key="secondIndex" :value="item">
                                    {{ item }}
                                </option>
                            </select>
                        </div>
                        <div v-else class="flex items-center gap-4">
                            <div class="w-full">
                                <Slider
                                    v-model="sliderValues[question.answer_concern_field
                                        ? question.answer_concern_field.id : question.custom_concern_field]"
                                    :min="0" :max="10" show-tooltip="focus"
                                />
                            </div>
                            <input
                                v-model="sliderValues[question.answer_concern_field
                                    ? question.answer_concern_field.id : question.custom_concern_field]"
                                type="number" aria-describedby="helper-text-explanation"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
                                focus:ring-blue-500 focus:border-blue-500 block w-[75px] p-2.5 font-medium mt-1 text-center"
                                required
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-[80px] shrink-0 relative">
            <div class="absolute top-1/2 transform right-0 -translate-y-1/2 flex flex-col gap-6">
                <button class="w-14 h-14 bg-white border border-gray-light rounded-[8px] flex items-center justify-center" @click="goToPrevious">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 32 32" fill="none">
                        <path d="M26.7081 20.7076C26.6152 20.8005 26.5049 20.8743 26.3835 20.9246C26.2621 20.9749 26.132 21.0008 26.0006 21.0008C25.8691 21.0008 25.739 20.9749 25.6176 20.9246C25.4962 20.8743 25.3859 20.8005 25.2931 20.7076L16.0006 11.4138L6.70806 20.7076C6.52042 20.8952 6.26592 21.0006 6.00056 21.0006C5.73519 21.0006 5.4807 20.8952 5.29306 20.7076C5.10542 20.5199 5 20.2654 5 20.0001C5 19.7347 5.10542 19.4802 5.29306 19.2926L15.2931 9.29255C15.3859 9.19958 15.4962 9.12582 15.6176 9.07549C15.739 9.02517 15.8691 8.99927 16.0006 8.99927C16.132 8.99927 16.2621 9.02517 16.3835 9.07549C16.5049 9.12582 16.6152 9.19958 16.7081 9.29255L26.7081 19.2926C26.801 19.3854 26.8748 19.4957 26.9251 19.6171C26.9754 19.7385 27.0013 19.8686 27.0013 20.0001C27.0013 20.1315 26.9754 20.2616 26.9251 20.383C26.8748 20.5044 26.801 20.6147 26.7081 20.7076Z" fill="#1A201E" />
                    </svg>
                </button>
                <button class="w-14 h-14 bg-white border border-gray-light rounded-[8px] flex items-center justify-center" @click="goToNext">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 32 32" fill="none">
                        <path d="M5.29194 11.2924C5.38482 11.1995 5.49511 11.1257 5.6165 11.0754C5.7379 11.0251 5.86803 10.9992 5.99944 10.9992C6.13086 10.9992 6.26099 11.0251 6.38239 11.0754C6.50378 11.1257 6.61407 11.1995 6.70694 11.2924L15.9994 20.5862L25.2919 11.2924C25.4796 11.1048 25.7341 10.9994 25.9994 10.9994C26.2648 10.9994 26.5193 11.1048 26.7069 11.2924C26.8946 11.4801 27 11.7346 27 11.9999C27 12.2653 26.8946 12.5198 26.7069 12.7074L16.7069 22.7074C16.6141 22.8004 16.5038 22.8742 16.3824 22.9245C16.261 22.9748 16.1309 23.0007 15.9994 23.0007C15.868 23.0007 15.7379 22.9748 15.6165 22.9245C15.4951 22.8742 15.3848 22.8004 15.2919 22.7074L5.29194 12.7074C5.19897 12.6146 5.12521 12.5043 5.07488 12.3829C5.02456 12.2615 4.99866 12.1314 4.99866 11.9999C4.99866 11.8685 5.02456 11.7384 5.07488 11.617C5.12521 11.4956 5.19897 11.3853 5.29194 11.2924Z" fill="#1A201E" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<style src="@vueform/slider/themes/default.css"></style>

<style>
.onboarding-card-container{
    @apply w-full absolute z-10 py-1 md:py-2 flex items-center;
    transition: 1s;
}
.onboarding-card{
    @apply h-full w-full border border-gray-light rounded-[16px] p-8 bg-white max-h-[300px];
}
</style>
