<script setup lang="ts">
import moment from 'moment'
import { computed, onMounted, ref } from 'vue'
import { News } from '@/interfaces/News';
import { Event } from '@/interfaces/Event';
import EventInformations from '@/components/Event/EventInformations.vue'
import axios from 'axios'
import { Category } from '@/interfaces/Category'
import { Domain } from '@/interfaces/Domain'

const props = withDefaults(defineProps<{
    news?: Array<News>|null,
    events?: Array<Event>|null,
    eventsTitle?: string,
    newsTitle?: string,
    domains?: Array<Domain>|null,
    categories?: Array<Category>|null,
    dynamicFetch?: boolean,
}>(), {
    news: null,
    events: null,
    domains: null,
    categories: null,
    eventsTitle: 'Événements à venir',
    newsTitle: 'Les dernières actualités',
    dynamicFetch: false
});

const fetchData = () => {
    axios.post('/api/get-news', {
        domains: props.domains?.map(domain => domain.id),
        categories: props.categories?.map(category => category.id)
    })
        .then(response => computedNews.value = response.data.news)
        .catch(error => console.log(error))

    axios.post('/api/get-events', {
        domains: props.domains?.map(domain => domain.id),
        categories: props.categories?.map(category => category.id)
    })
        .then(response => computedEvents.value = response.data.news)
        .catch(error => console.log(error))
}

const computedNews = ref()
const computedEvents = ref()

const sortedNews = computed(() => {
    const newsCopy = [...computedNews.value];
    return newsCopy.sort((a, b) => +moment(b.date) - +moment(a.date)).slice(0, 3);
});

const upcomingEvents = computed(() => {
    const now = moment();
    const eventsCopy = [...computedEvents.value];
    return eventsCopy
        .filter(event => moment(event.start_date).isSameOrAfter(now) || moment(event.end_date).isSameOrAfter(now))
        .sort((a, b) => +moment(a.start_date) - +moment(b.start_date))
        .slice(0, 4);
});

onMounted(() => {
    if (props.dynamicFetch){
        fetchData()
    }
    else {
        computedNews.value = props.news;
        computedEvents.value = props.events;
    }
})
</script>

<template>
    <div
        v-if="computedNews?.length > 0 || computedEvents?.length > 0"
        class="space-y-12 lg:space-y-0 lg:flex gap-24 items-start"
    >
        <div v-if="computedNews?.length > 0" class="w-full lg:w-3/5 shrink-0">
            <h3 class="mb-6">
                {{ props.newsTitle }}
            </h3>

            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 gap-6">
                <a
                    v-for="(actu, index) in sortedNews"
                    :key="index"
                    :href="actu.external_link ? actu.external_link : actu.permalink"
                    :target="actu.external_link ? '_blank' : '_self'"
                    class="flex flex-col md:flex-row md:items-center md:gap-6 group border border-gray-light rounded-[32px]
                    overflow-hidden md:w-fit hover:border-primary-extra-light transition duration-300 w-fit"
                    :class="index % 2 === 0 ? 'md:pr-6' : 'md:flex-row-reverse md:pl-6 pr-0'"
                >
                    <img
                        class="w-full h-full max-h-[150px] md:max-h-[200px] md:max-w-[230px] md:w-56 object-cover"
                        :src="actu.cover?.permalink ?? actu.cover_url" alt=""
                    >
                    <div class="my-5 px-4 md:px-0">
                        <p class="new-border pl-4 mb-2 md:text-lg font-medium group-hover:text-primary-light transition duration-300">
                            {{ actu.title }}
                        </p>
                        <div class="flex items-center gap-2">
                            <span class="uppercase text-xs font-semibold">Actualités</span>
                            <span class="h-1 w-1 bg-gray-400 block rounded-full" />
                            <span class="text-gray-medium">{{ moment(actu.date).format("DD.MM.YYYY") }}</span>
                        </div>
                    </div>
                </a>
            </div>

            <a
                href="/actualites"
                class="font-semibold flex items-center gap-2 hover:text-primary-light transition duration-150 mt-12"
            >
                Voir plus de news
                <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                    <path d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C23.9966 8.81843 22.7313 5.76814 20.4816 3.51843C18.2319 1.26872 15.1816 0.00335979 12 0ZM12 22.1538C9.99176 22.1538 8.02862 21.5583 6.35883 20.4426C4.68904 19.3269 3.38759 17.7411 2.61907 15.8857C1.85055 14.0303 1.64947 11.9887 2.04126 10.0191C2.43305 8.04943 3.40011 6.24019 4.82015 4.82015C6.24019 3.4001 8.04943 2.43304 10.0191 2.04126C11.9887 1.64947 14.0303 1.85055 15.8857 2.61907C17.7411 3.38759 19.3269 4.68903 20.4426 6.35882C21.5583 8.02861 22.1538 9.99176 22.1538 12C22.1508 14.692 21.08 17.2729 19.1765 19.1765C17.2729 21.08 14.692 22.1508 12 22.1538ZM17.2685 11.3469C17.3543 11.4326 17.4224 11.5345 17.4688 11.6465C17.5153 11.7586 17.5392 11.8787 17.5392 12C17.5392 12.1213 17.5153 12.2414 17.4688 12.3535C17.4224 12.4655 17.3543 12.5673 17.2685 12.6531L13.5762 16.3454C13.403 16.5186 13.168 16.6159 12.9231 16.6159C12.6781 16.6159 12.4432 16.5186 12.27 16.3454C12.0968 16.1722 11.9995 15.9373 11.9995 15.6923C11.9995 15.4474 12.0968 15.2124 12.27 15.0392L14.3873 12.9231H7.38462C7.1398 12.9231 6.90502 12.8258 6.73191 12.6527C6.55879 12.4796 6.46154 12.2448 6.46154 12C6.46154 11.7552 6.55879 11.5204 6.73191 11.3473C6.90502 11.1742 7.1398 11.0769 7.38462 11.0769H14.3873L12.27 8.96077C12.0968 8.78756 11.9995 8.55264 11.9995 8.30769C11.9995 8.06274 12.0968 7.82782 12.27 7.65461C12.4432 7.48141 12.6781 7.3841 12.9231 7.3841C13.168 7.3841 13.403 7.48141 13.5762 7.65461L17.2685 11.3469Z" />
                </svg>
            </a>
        </div>

        <div v-if="computedEvents?.length > 0" class="w-full">
            <h3 class="mb-6">
                {{ props.eventsTitle }}
            </h3>

            <EventInformations v-for="event in upcomingEvents" :key="event.id" :event="event" class="mb-8" />

            <div v-if="upcomingEvents.length === 0">
                Aucun événement à venir
            </div>
            <a
                href="/evenements"
                class="font-semibold flex items-center gap-2 hover:text-secondary-dark transition duration-150 mt-12"
            >
                Voir plus d’événements
                <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                    <path d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C23.9966 8.81843 22.7313 5.76814 20.4816 3.51843C18.2319 1.26872 15.1816 0.00335979 12 0ZM12 22.1538C9.99176 22.1538 8.02862 21.5583 6.35883 20.4426C4.68904 19.3269 3.38759 17.7411 2.61907 15.8857C1.85055 14.0303 1.64947 11.9887 2.04126 10.0191C2.43305 8.04943 3.40011 6.24019 4.82015 4.82015C6.24019 3.4001 8.04943 2.43304 10.0191 2.04126C11.9887 1.64947 14.0303 1.85055 15.8857 2.61907C17.7411 3.38759 19.3269 4.68903 20.4426 6.35882C21.5583 8.02861 22.1538 9.99176 22.1538 12C22.1508 14.692 21.08 17.2729 19.1765 19.1765C17.2729 21.08 14.692 22.1508 12 22.1538ZM17.2685 11.3469C17.3543 11.4326 17.4224 11.5345 17.4688 11.6465C17.5153 11.7586 17.5392 11.8787 17.5392 12C17.5392 12.1213 17.5153 12.2414 17.4688 12.3535C17.4224 12.4655 17.3543 12.5673 17.2685 12.6531L13.5762 16.3454C13.403 16.5186 13.168 16.6159 12.9231 16.6159C12.6781 16.6159 12.4432 16.5186 12.27 16.3454C12.0968 16.1722 11.9995 15.9373 11.9995 15.6923C11.9995 15.4474 12.0968 15.2124 12.27 15.0392L14.3873 12.9231H7.38462C7.1398 12.9231 6.90502 12.8258 6.73191 12.6527C6.55879 12.4796 6.46154 12.2448 6.46154 12C6.46154 11.7552 6.55879 11.5204 6.73191 11.3473C6.90502 11.1742 7.1398 11.0769 7.38462 11.0769H14.3873L12.27 8.96077C12.0968 8.78756 11.9995 8.55264 11.9995 8.30769C11.9995 8.06274 12.0968 7.82782 12.27 7.65461C12.4432 7.48141 12.6781 7.3841 12.9231 7.3841C13.168 7.3841 13.403 7.48141 13.5762 7.65461L17.2685 11.3469Z" />
                </svg>
            </a>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.new-border{
    border-left: 5px solid #F1C99B;
}
</style>
